/**
 * @generated SignedSource<<e8a7e06aab8853a72038c761547ca3b0>>
 * @relayHash e8d0706b2b46e6d59c03a0e87eee0904
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/useAddressAutocompleteDetailsQuery

import { ConcreteRequest } from 'relay-runtime';
export type useAddressAutocompleteDetailsQuery$variables = {
  placeId: string;
  sessionToken: string;
};
export type useAddressAutocompleteDetailsQuery$data = {
  readonly viewer: {
    readonly addressAutocompleteDetails: {
      readonly city: string | null;
      readonly country: string | null;
      readonly stateOrRegion: string | null;
      readonly zipCode: string | null;
    } | null;
  };
};
export type useAddressAutocompleteDetailsQuery = {
  response: useAddressAutocompleteDetailsQuery$data;
  variables: useAddressAutocompleteDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionToken"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "placeId",
      "variableName": "placeId"
    },
    {
      "kind": "Variable",
      "name": "sessionToken",
      "variableName": "sessionToken"
    }
  ],
  "concreteType": "AddressAutocompleteDetails",
  "kind": "LinkedField",
  "name": "addressAutocompleteDetails",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stateOrRegion",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddressAutocompleteDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddressAutocompleteDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/useAddressAutocompleteDetailsQuery",
    "metadata": {},
    "name": "useAddressAutocompleteDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d884431f7ec0f990b5c1a9f1839675f4";

export default node;
